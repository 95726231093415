import React, { useContext } from "react";

import { ThemeContext } from "../../context/ThemeContext";

import DynamicIcon from "./DynamicIcon";

const ThemeToggle = (props) => {
    const { className } = props;
    const { theme, setTheme } = useContext(ThemeContext);

    return (
        <button
            aria-label="Toggle Darkmode"
            type="button"
            className={`rounded-full focus:outline-none ${className}`}
            onClick={() => setTheme(theme === "dark" ? "light" : "dark")}>
            {theme === "dark" ? (
                <DynamicIcon iconPackage="fa" icon="FaSun" />
            ) : (
                <DynamicIcon iconPackage="fa" icon="FaMoon" />
            )}
        </button>
    );
};

export default ThemeToggle;
