export const FooterCategory = (props) => {
    const { title, children } = props;

    return (
        <div className="text-center md:text-left">
            <div className="text-gray-600 dark:text-gray-100 font-bold tracking-widest uppercase mb-4">
                {title}
            </div>
            <nav className="flex flex-col gap-4">{children}</nav>
        </div>
    );
};

export default FooterCategory;
