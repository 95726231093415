import { Suspense, lazy } from "react";

import { FaSpinner } from "react-icons/fa";
import { AnimationOnScroll } from "react-animation-on-scroll";

import Hero from "./components/Hero";

const LazyContent = lazy(() => import("./Content"));

export const App = () => {
    const scrollWithOffset = (event) => {
        const yCoordinate = event.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -50;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    };

    return (
        <div className="h-full">
            <div id="home" className="bg-gray-400 dark:bg-gray-900">
                <AnimationOnScroll delay={200} animateOnce animateIn="animate__fadeInUp">
                    <Hero scroll={scrollWithOffset} />
                </AnimationOnScroll>
                <Suspense
                    fallback={<FaSpinner className="animate-spin text-white text-5xl mx-auto" />}>
                    <LazyContent />
                </Suspense>
            </div>
        </div>
    );
};

export default App;
