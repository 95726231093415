// import * as SiIcon from "react-icons/si/index.esm";
// import * as FaIcon from "react-icons/fa/index.esm";
// import * as HiIcon from "react-icons/hi/index.esm";

import {
    SiLua,
    SiHtml5,
    SiCss3,
    SiJavascript,
    SiJquery,
    SiPhp,
    SiMysql,
    SiBootstrap,
    SiWordpress,
    SiLaravel,
    SiNodedotjs,
    SiGit,
    SiLinux,
    SiPython,
    SiJson,
    SiMongodb,
    SiAngular,
    SiTypescript,
    SiFirebase,
    SiSpring,
    SiJsonwebtokens,
    SiReact,
    SiDocker,
    SiTailwindcss,
    SiNginx,
    SiKubernetes,
    SiGithubactions,
    SiWebpack,
    SiRedux,
    SiNextdotjs,
    SiGraphql,
} from "react-icons/si";

import {
    FaGithub,
    FaGlobe,
    FaLinkedin,
    FaHourglassHalf,
    FaTimes,
    FaSpinner,
    FaCheck,
    FaEnvelope,
    FaGitlab,
    FaStar,
    FaFistRaised,
    FaSwimmer,
    FaDumbbell,
    FaGamepad,
    FaCode,
    FaBiking,
    FaGlobeEurope,
    FaPhp,
    FaPython,
    FaNodeJs,
    FaReact,
    FaWordpress,
    FaTachometerAlt,
    FaMicrochip,
    FaMemory,
    FaSun,
    FaMoon,
    FaBars,
    FaJava,
} from "react-icons/fa";

import { BiLogoGoLang } from "react-icons/bi";

import {
    HiOutlineAcademicCap,
    HiOutlineBriefcase,
    HiOutlineSparkles,
    HiOutlineQuestionMarkCircle,
    HiOutlineStar,
} from "react-icons/hi";

import { BsChevronDown, BsClock, BsPatchCheck } from "react-icons/bs";
import { AiFillCaretUp } from "react-icons/ai";

import { GiHamburgerMenu } from "react-icons/gi";

export const DynamicIcon = (props) => {
    const iconPackages = {
        bi: {
            BiLogoGoLang,
        },
        si: {
            SiLua,
            SiHtml5,
            SiCss3,
            SiJavascript,
            SiJquery,
            SiPhp,
            SiMysql,
            SiBootstrap,
            SiWordpress,
            SiLaravel,
            SiNodedotjs,
            SiGit,
            SiLinux,
            SiPython,
            SiJson,
            SiMongodb,
            SiAngular,
            SiTypescript,
            SiFirebase,
            SiSpring,
            SiJsonwebtokens,
            SiReact,
            SiDocker,
            SiTailwindcss,
            SiNginx,
            SiKubernetes,
            SiGithubactions,
            SiWebpack,
            SiRedux,
            SiNextdotjs,
            SiGraphql,
        },
        hi: {
            HiOutlineAcademicCap,
            HiOutlineBriefcase,
            HiOutlineSparkles,
            HiOutlineQuestionMarkCircle,
            HiOutlineStar,
        },
        fa: {
            FaGithub,
            FaGlobe,
            FaLinkedin,
            FaHourglassHalf,
            FaTimes,
            FaSpinner,
            FaCheck,
            FaEnvelope,
            FaGitlab,
            FaStar,
            FaFistRaised,
            FaSwimmer,
            FaDumbbell,
            FaGamepad,
            FaCode,
            FaBiking,
            FaGlobeEurope,
            FaPhp,
            FaPython,
            FaNodeJs,
            FaReact,
            FaWordpress,
            FaTachometerAlt,
            FaMicrochip,
            FaMemory,
            FaSun,
            FaMoon,
            FaBars,
            FaJava,
        },
        ai: {
            AiFillCaretUp,
        },
        bs: {
            BsChevronDown,
            BsClock,
            BsPatchCheck,
        },
        gi: {
            GiHamburgerMenu,
        },
    };

    const { iconPackage, icon, className } = props;

    const CustomIcon = () => {
        const Icon = iconPackages[iconPackage][icon];
        return <Icon className={className} />;
    };

    return <CustomIcon />;
};

export default DynamicIcon;
