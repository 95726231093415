export const SectionTitle = (props) => {
    const { id, title } = props;

    return (
        <h2
            id={id}
            className="text-4xl font-normal leading-normal text-center py-12 text-primary-default dark:text-primary-dark">
            {title}
        </h2>
    );
};

export default SectionTitle;
