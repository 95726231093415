import React, { useState, useRef } from "react";
import { NavHashLink } from "react-router-hash-link";

import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import { motion, AnimatePresence, useCycle } from "framer-motion";

import menu from "../data/menu.json";
import ThemeToggle from "./Util/ThemeToggle";
import DynamicIcon from "./Util/DynamicIcon";
import ReactCountryFlag from "react-country-flag";

export const Header = (props) => {
    const { scroll } = props;
    const { t, i18n } = useTranslation();

    const languageMenuRef = useRef();
    const navbarMenuRef = useRef();
    const [isNavbarMenuOpen, setNavbarMenuOpen] = useState(false);
    const [isLanguageMenuOpen, setLanguageMenuOpen] = useState(false);
    const [isHamburgerMenuOpen, toggleHamburgerMenu] = useCycle(
        {
            visible: false,
            rotate: 0,
        },
        {
            visible: true,
            rotate: -180,
        }
    );

    useOnClickOutside(languageMenuRef, () => setLanguageMenuOpen(false));
    useOnClickOutside(navbarMenuRef, () => setNavbarMenuOpen(false));

    const changeLanguage = (event) => {
        const language = event.target.lastChild?.id || event.target?.id || event.target?.title;
        i18n.changeLanguage(language);
        setLanguageMenuOpen(false);
    };

    const renderMultiLanguageFlagMenuItem = (
        flagAlias,
        title,
        name,
        buttonClassName,
        valueClassName
    ) => (
        <button className={buttonClassName} onClick={changeLanguage}>
            <ReactCountryFlag className="text-2xl" countryCode={flagAlias} svg title={title} />
            <p id={title} className={valueClassName}>
                {name}
            </p>
        </button>
    );

    const renderNavbarMenuItems = (desktop) =>
        menu
            .filter(({ hidden }) => !hidden)
            .map(({ link, value }, index) => (
                <NavHashLink
                    key={`menu-${index}`}
                    to={`/${link}`}
                    scroll={scroll}
                    onClick={() => isNavbarMenuOpen && setNavbarMenuOpen(!isNavbarMenuOpen)}
                    className={`${
                        desktop
                            ? "hidden lg:flex hover:text-primary-default dark:hover:text-primary-dark"
                            : "lg:hidden select-none py-2 w-full hover:bg-primary-default dark:hover:bg-primary-dark hover:text-gray-600 dark:hover:text-gray-300"
                    } text-gray-600 dark:text-gray-300 text-lg font-semibold transition duration-100`}
                    activeClassName="text-primary-default dark:text-primary-dark">
                    <p>{t(value)}</p>
                </NavHashLink>
            ));

    const renderLogo = () => (
        <NavHashLink
            to="/#home"
            scroll={scroll}
            className="inline-flex items-center text-gray-900 dark:text-gray-300 text-2xl md:text-2xl font-bold gap-2.5">
            <DynamicIcon
                iconPackage="fa"
                icon="FaCode"
                className="flex-shrink-0 h-auto w-10 text-primary-default dark:text-primary-dark"
            />
            Mehmet
            <span className="text-primary-default dark:text-primary-dark">TETIK</span>
        </NavHashLink>
    );

    return (
        <header className="flex justify-between items-center px-4 md:px-8 mx-auto py-6 bg-gray-100 dark:bg-gray-900 fixed top-0 w-full z-50 shadow-2xl">
            <div className="flex items-center justify-between">{renderLogo()}</div>
            <div className="flex gap-8 lg:gap-10 xl:gap-12">{renderNavbarMenuItems(true)}</div>
            <div
                className="gap-4 text-gray-600 dark:text-gray-300 text-2xl hidden lg:flex"
                ref={languageMenuRef}>
                <button
                    className="hover:text-primary-default dark:hover:text-primary-dark"
                    onClick={() => setLanguageMenuOpen(!isLanguageMenuOpen)}>
                    <DynamicIcon iconPackage="fa" icon="FaGlobeEurope" />
                </button>
                {isLanguageMenuOpen && (
                    <div className="absolute top-16 mt-3 right-1 pr-1 p-1 bg-slate-300 dark:bg-slate-700 shadow-2xl w-40">
                        <DynamicIcon
                            iconPackage="ai"
                            icon="AiFillCaretUp"
                            className="absolute -top-4 left-0 right-0 mx-auto text-slate-300 dark:text-slate-700"
                        />
                        {renderMultiLanguageFlagMenuItem(
                            "GB",
                            "en",
                            "English",
                            "flex px-4 py-2 text-sm dark:text-gray-200 text-gray-900 w-full hover:bg-primary-default dark:hover:bg-primary-dark transition duration-100",
                            "ml-5 font-semibold"
                        )}
                        {renderMultiLanguageFlagMenuItem(
                            "NL",
                            "nl",
                            "Nederlands",
                            "flex px-4 py-2 text-sm dark:text-gray-200 text-gray-900 w-full hover:bg-primary-default dark:hover:bg-primary-dark transition duration-100",
                            "ml-5 font-semibold"
                        )}
                        {renderMultiLanguageFlagMenuItem(
                            "TR",
                            "tr",
                            "Türkçe",
                            "flex px-4 py-2 text-sm dark:text-gray-200 text-gray-900 w-full hover:bg-primary-default dark:hover:bg-primary-dark transition duration-100",
                            "ml-5 font-semibold"
                        )}
                    </div>
                )}
                <ThemeToggle className="hover:text-primary-default dark:hover:text-primary-dark" />
            </div>
            <div className="flex lg:hidden" ref={navbarMenuRef}>
                <button
                    onClick={() => {
                        setNavbarMenuOpen(!isNavbarMenuOpen);
                        toggleHamburgerMenu();
                    }}>
                    {!isNavbarMenuOpen ? (
                        <motion.div animate={isHamburgerMenuOpen} transition={{ duration: 0.5 }}>
                            <DynamicIcon
                                iconPackage="fa"
                                icon="FaBars"
                                className="text-gray-600 dark:text-gray-300 text-3xl hover:text-primary-default dark:hover:text-primary-dark"
                            />
                        </motion.div>
                    ) : (
                        <motion.div animate={isHamburgerMenuOpen} transition={{ duration: 0.5 }}>
                            <DynamicIcon
                                iconPackage="fa"
                                icon="FaTimes"
                                className="text-gray-600 dark:text-gray-300 text-3xl hover:text-primary-default dark:hover:text-primary-dark"
                            />
                        </motion.div>
                    )}
                </button>
                {isNavbarMenuOpen && (
                    <AnimatePresence>
                        <motion.div
                            className="absolute bg-slate-300 dark:bg-slate-800 w-full left-0 top-20 mt-2 text-center shadow-2xl"
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            variants={{
                                hidden: {
                                    height: 0,
                                    transition: {
                                        when: "afterChildren",
                                    },
                                },
                                visible: {
                                    height: "auto",
                                    transition: {
                                        when: "beforeChildren",
                                        duration: 0.2,
                                    },
                                },
                            }}>
                            <motion.div
                                className="flex flex-col"
                                variants={{
                                    hidden: {
                                        opacity: 0,
                                    },
                                    visible: {
                                        opacity: 1,
                                    },
                                }}>
                                {renderNavbarMenuItems(false)}
                                <div className="flex flex-row justify-between py-2">
                                    {renderMultiLanguageFlagMenuItem(
                                        "GB",
                                        "en",
                                        "English",
                                        "w-full hover:bg-primary-default dark:hover:bg-primary-dark py-2",
                                        "text-gray-600 dark:text-gray-300 font-semibold"
                                    )}
                                    {renderMultiLanguageFlagMenuItem(
                                        "NL",
                                        "nl",
                                        "Nederlands",
                                        "w-full hover:bg-primary-default dark:hover:bg-primary-dark py-2",
                                        "text-gray-600 dark:text-gray-300 font-semibold"
                                    )}
                                    {renderMultiLanguageFlagMenuItem(
                                        "TR",
                                        "tr",
                                        "Türkçe",
                                        "w-full hover:bg-primary-default dark:hover:bg-primary-dark py-2",
                                        "text-gray-600 dark:text-gray-300 font-semibold"
                                    )}
                                </div>
                                <ThemeToggle className="text-gray-600 dark:text-gray-300 text-3xl hover:text-primary-default dark:hover:text-primary-dark mx-auto pb-4" />
                            </motion.div>
                        </motion.div>
                    </AnimatePresence>
                )}
            </div>
        </header>
    );
};

export default Header;
