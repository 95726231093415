import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "./context/ThemeContext";

import App from "./App";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Roadmap from "./routes/Roadmap";
import reportWebVitals from "./reportWebVitals";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration.js";

import "./i18n";
import "./index.css";
import "animate.css/animate.min.css";

const container = document.getElementById("root");
const root = createRoot(container);

const scrollWithOffset = (event) => {
    const yCoordinate = event.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -50;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

root.render(
    <ThemeProvider>
        <BrowserRouter>
            <Header scroll={scrollWithOffset} />
            <Switch>
                <Route exact path="/">
                    <App />
                </Route>
                <Route path="/roadmap">
                    <Roadmap />
                </Route>
            </Switch>
            <Footer scroll={scrollWithOffset} />
        </BrowserRouter>
    </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
