import { NavHashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

import DynamicIcon from "./Util/DynamicIcon";
import socialLinks from "../data/social-links.json";

export const Hero = (props) => {
    const { scroll } = props;
    const { t } = useTranslation();

    const getAge = (birthDate) =>
        Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);

    const renderSocialLinks = () =>
        socialLinks.map(({ href, className, icon }, index) => (
            <a
                aria-label={`menu-link-${index}`}
                key={`menu-${index}`}
                href={href}
                target="_blank"
                rel="noreferrer"
                className={className}>
                <DynamicIcon {...icon} className="text-5xl" />
            </a>
        ));

    return (
        <section className="h-screen">
            <div
                className="bg-fixed bg-no-repeat bg-cover bg-center h-full opacity-100"
                style={{ backgroundImage: 'url("/images/hero-1.webp")', filter: "blur(5px)" }}
            />
            <div className="absolute inset-0 flex justify-center items-center">
                <div className="w-full mx-2 max-w-md sm:max-w-4xl backdrop-filter backdrop-blur-md backdrop-brightness-50 bg-black/50 rounded-sm text-white py-12 px-4 sm:px-8">
                    <h1 className="text-2xl sm:text-4xl pb-5 text-center">
                        {t("hero.title")}{" "}
                        <span className="text-primary-default dark:text-primary-dark font-bold">
                            Mehmet Tetik
                        </span>
                    </h1>
                    <p className="mt-6 text-center text-lg font-semibold sm:text-xl">
                        “{t("hero.quote.part1")} {getAge("1998-03-13")}
                        {t("hero.quote.part2")}{" "}
                        <span className="font-medium text-primary-default dark:text-primary-dark">
                            {t("hero.quote.part3")}
                        </span>
                        {t("hero.quote.part4")}”
                    </p>
                    <div className="flex justify-evenly my-12 w-full">{renderSocialLinks()}</div>
                    <div className="flex items-center">
                        <NavHashLink
                            scroll={scroll}
                            to="#about"
                            className="uppercase mx-auto flex items-center bg-transparent hover:bg-primary-default text-primary-default dark:text-primary-dark font-semibold dark:hover:text-white hover:text-white py-2 px-4 border dark:border-primary-dark border-primary-default hover:border-transparent rounded-sm">
                            {t("hero.button.read.more")}
                            <DynamicIcon
                                iconPackage="bs"
                                icon="BsChevronDown"
                                className="ml-1 text-2xl animate-bounce"
                            />
                        </NavHashLink>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
