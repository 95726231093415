import { NavHashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

import menu from "../data/menu.json";
import projects from "../data/projects.json";
import usefulLinks from "../data/useful-links.json";
import WaveDivider from "./WaveDivider";
import socialLinks from "../data/social-links.json";
import DynamicIcon from "./Util/DynamicIcon";
import packageJson from "../../package.json";
import FooterCategory from "./UI/FooterCategory";

export const Footer = (props) => {
    const { scroll } = props;
    const { t } = useTranslation();

    const renderSocialLinks = () =>
        socialLinks.map(({ href, icon }, index) => (
            <a
                aria-label={`social-link-${index}`}
                key={`social-menu-${index}`}
                href={href}
                target="_blank"
                rel="noreferrer"
                className="text-gray-400 hover:text-gray-500 active:text-gray-600 transition duration-100">
                <DynamicIcon {...icon} />
            </a>
        ));

    const renderNavbarMenuItems = () =>
        menu.map(({ link, value }, index) => (
            <div
                key={`footer-menu-${index}`}
                className="text-gray-400 transition duration-100 text-opacity-100">
                <NavHashLink
                    to={`/${link}`}
                    scroll={scroll}
                    className="dark:hover:text-primary-dark hover:text-primary-default">
                    {t(value)}
                </NavHashLink>
            </div>
        ));

    const renderProjects = () =>
        projects
            .filter(({ disabled }) => !disabled)
            .map(({ title, url }, index) => (
                <div
                    key={`footer-project-${index}`}
                    className="text-gray-400 transition duration-100 text-opacity-100">
                    <a
                        aria-label={`project-link-${index}`}
                        href={url}
                        target="_blank"
                        rel="noreferrer"
                        className="dark:hover:text-primary-dark hover:text-primary-default">
                        {title}
                    </a>
                </div>
            ));

    const renderUsefulLinks = () =>
        usefulLinks.map(({ title, multiLanguage, url }, index) => {
            const newTitle = multiLanguage ? t(title) : title;

            return (
                <div
                    key={`footer-useful-link-${index}`}
                    className="text-gray-400 transition duration-100 text-opacity-100">
                    {url.includes("http") ? (
                        <a
                            aria-label={`useful-link-${index}`}
                            href={url}
                            target="_blank"
                            rel="noreferrer"
                            className="dark:hover:text-primary-dark hover:text-primary-default">
                            {newTitle}
                        </a>
                    ) : (
                        <NavHashLink
                            to={url}
                            scroll={scroll}
                            className="dark:hover:text-primary-dark hover:text-primary-default">
                            {newTitle}
                        </NavHashLink>
                    )}
                </div>
            );
        });

    return (
        <div className="bg-gray-100 dark:bg-gray-900">
            <WaveDivider fillColor="text-gray-300 dark:text-gray-700" />
            <footer className="max-w-screen-2xl px-4 md:px-8 mx-auto">
                <div className="flex flex-col sm:flex-row justify-between gap-12 lg:gap-8 pt-10 lg:pt-12 mb-16">
                    <div className="w-full lg:w-1/2">
                        <div className="lg:-mt-2 mb-4 text-gray-600 dark:text-gray-100">
                            <NavHashLink
                                to="/#home"
                                scroll={scroll}
                                className="inline-flex items-center text-xl md:text-2xl font-bold gap-2"
                                aria-label="logo">
                                <DynamicIcon
                                    iconPackage="fa"
                                    icon="FaCode"
                                    className="flex-shrink-0 h-auto w-10 text-primary-default dark:text-primary-dark"
                                />
                                Mehmet Tetik
                            </NavHashLink>
                        </div>
                        <p className="text-gray-400 sm:pr-8">{t("footer.description1")}</p>
                        <p className="text-gray-400 sm:pr-8 mb-6">{t("footer.description2")}</p>
                        <div className="flex gap-4">{renderSocialLinks()}</div>
                    </div>
                    <div className="hidden justify-between lg:flex flex-1">
                        <FooterCategory title={t("header.menu")}>
                            {renderNavbarMenuItems()}
                        </FooterCategory>
                        <FooterCategory title={t("header.menu5")}>
                            {renderProjects()}
                        </FooterCategory>
                        <FooterCategory title={t("header.menu7")}>
                            {renderUsefulLinks()}
                        </FooterCategory>
                    </div>
                </div>
                <div className="flex mb-8 justify-between lg:hidden">
                    <FooterCategory title={t("header.menu")}>
                        {renderNavbarMenuItems()}
                    </FooterCategory>
                    <FooterCategory title={t("header.menu5")}>{renderProjects()}</FooterCategory>
                    <FooterCategory title={t("header.menu7")}>{renderUsefulLinks()}</FooterCategory>
                </div>
                <div className="text-gray-400 text-sm text-center border-t border-gray-800 py-8">
                    <p>
                        © 2021 - {new Date().getFullYear()} <strong>Mehmet Tetik</strong> - All
                        rights reserved.
                    </p>
                    <p>v{packageJson.version}</p>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
