import DynamicIcon from "../components/Util/DynamicIcon";
import SectionTitle from "../components/UI/SectionTitle";
import roadmapEvents from "../data/roadmap-events.json";
import SectionDescription from "../components/UI/SectionDescription";

import { EventTimeline } from "react-tailwind-timeline-component";
import { useTranslation } from "react-i18next";

export const Roadmap = () => {
    const { t } = useTranslation();

    const renderStatus = (status) => {
        switch (status) {
            case "completed":
                return {
                    icon: (
                        <DynamicIcon
                            iconPackage="fa"
                            icon="FaCheck"
                            className="mx-auto h-8 w-8 animate-pulse text-white"
                        />
                    ),
                    iconBackgroundClassName: "ring-green-500 ring-4 bg-green-400",
                    lineClassName: "border-dashed border-green-500",
                };

            case "inProgress":
                return {
                    icon: (
                        <DynamicIcon
                            iconPackage="fa"
                            icon="FaSpinner"
                            className="mx-auto h-10 w-10 text-white animate-spin"
                        />
                    ),
                    iconBackgroundClassName: "ring-yellow-500 ring-4 bg-yellow-400",
                    lineClassName: "border-dashed border-yellow-500",
                };
            case "planned":
                return {
                    icon: (
                        <DynamicIcon
                            iconPackage="fa"
                            icon="FaHourglassHalf"
                            className="mx-auto h-8 w-8 animate-pulse text-white"
                        />
                    ),
                    iconBackgroundClassName: "ring-blue-500 ring-4 bg-blue-400",
                    lineClassName: "border-dashed border-blue-500",
                };
            case "cancelled":
                return {
                    icon: (
                        <DynamicIcon
                            iconPackage="fa"
                            icon="FaTimes"
                            className="mx-auto h-8 w-8 animate-pulse text-white"
                        />
                    ),
                    iconBackgroundClassName: "ring-red-500 ring-4 bg-red-400",
                    lineClassName: "border-dashed border-red-500",
                };
            default:
                return null;
        }
    };

    const renderRoadmapTimelineEvents = () =>
        roadmapEvents.map(
            ({ title, status, description, leftSideContent, rightSideContent }, index) => (
                <EventTimeline
                    key={`event-${index}`}
                    title={title}
                    description={t(description)}
                    icon={renderStatus(status).icon}
                    leftSideContent={leftSideContent}
                    rightSideContent={<DynamicIcon {...rightSideContent} />}
                    iconBackgroundClassName={renderStatus(status).iconBackgroundClassName}
                    lineClassName={renderStatus(status).lineClassName}
                    cardClassName="bg-slate-200 dark:bg-slate-900 dark:text-slate-200 text-slate-900"
                />
            )
        );

    return (
        <section className="bg-gray-300 dark:bg-gray-700">
            <SectionTitle title={t("roadmap.title")} />
            <SectionDescription>{t("roadmap.description")}</SectionDescription>
            <div className="mx-auto w-full max-w-xl sm:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
                {renderRoadmapTimelineEvents()}
            </div>
        </section>
    );
};

export default Roadmap;
