export const SectionDescription = (props) => {
    const { children } = props;

    return (
        <h3 className="text-lg font-normal leading-normal text-center pb-12 text-gray-600 dark:text-gray-200 italic px-5 lg:max-w-3xl mx-auto">
            {children}
        </h3>
    );
};

export default SectionDescription;
