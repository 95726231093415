export const WaveDivider = ({ fillColor }) => (
    <div className="inline-block relative w-full align-middle overflow-hidden h-48">
        <svg
            className="inline-block absolute top-0 left-0 w-full h-full"
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
        >
            <path
                className={`stroke-0 fill-current ${fillColor}`}
                d="M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z"
            />
        </svg>
    </div>
);

export default WaveDivider;
